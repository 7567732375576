(function ($) {
  Drupal.behaviors.smartStickyNavV1 = {
    attached: false,
    attach: function (context) {
      var $body = $('body');
      var $header = $('.js-site-header', context);
      var contentBlockSitewideBannerHeight = 0;
      var position = $(window).scrollTop();

      if (this.attached) {
        return;
      }
      this.attached = true;

      $(document).on('contentBlock.sitewideBanner', function (h) {
        contentBlockSitewideBannerHeight = h;
      });
      function _updateNavStatus() {
        var scroll = $(window).scrollTop();
        var templateHeight = $header.outerHeight(true) - contentBlockSitewideBannerHeight;

        // If the user scroll down, hide the Nav
        if (scroll <= position) {
          $body.toggleClass('site-header-formatter--hidden', false);
        } else if (scroll > 5) {
          $body.toggleClass('site-header-formatter--hidden', true);
          $body.toggleClass('site-header-formatter--sticky', true);
        }

        if (scroll <= templateHeight) {
          $body.toggleClass('site-header-formatter--sticky', false);
        }

        position = scroll;
      }
      $body.addClass('header-transition');
      $(window).off('scroll.gnavHeaderBlock').on('scroll.gnavHeaderBlock', _.throttle(_updateNavStatus, 100));
      _updateNavStatus();
    }
  };
})(jQuery);
